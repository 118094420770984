//https://ed92-109-37-129-131.ngrok-free.app/payment?checkout_session_id=cs_test_b1txF1jRXR05I11weA0efh4Yb2eI0NtTqBEz4QnwXUd0LRVzroBp3r8NDn
import * as React from "react"
import { navigate } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"
import styled from "styled-components"

const SuccessPage: React.FC = () => {
  const { isAuthenticated } = useAuth0()

  useEffect(() => {
    // Get the checkout session id from the query parameters
    if (typeof window !== "undefined") {
      setTimeout(() => {
        if (typeof window !== "undefined") {
          if (isAuthenticated) {
            navigate("/dashboard")
          } else {
            navigate("/")
          }
        }
      }, 5000) // 5000ms = 5s
    }
  }, [isAuthenticated])

  return (
    <>
      <Wrapper>
        <div>
          <p>Payment was cancelled. You will be redirected in a few seconds.</p>
        </div>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 4rem;
`

export default SuccessPage
